import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { IoPricetag, IoTime } from "react-icons/io5";
import Seo from "../components/seo";

const Delivery = () => {
  return (
    <Layout>
      <Seo
        title="Entrega - BIKESAFE"
        description="En Bike Safe, nos esforzamos por entregar su pedido a tiempo. Si el producto no está en stock por un período prolongado, nuestro equipo de atención al cliente se pondrá en contacto para informarle sobre el estado de su pedido."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5">Envío Gratuito en Todos los Pedidos</h1>
            <p className="flow-text">
              En bikesafe.es, siempre nos esforzamos por entregar su pedido
              puntualmente. Si el producto solicitado no está en stock durante
              un período prolongado, uno de nuestros representantes de atención
              al cliente se pondrá en contacto con usted, ya sea por correo
              electrónico o teléfono, para mantenerlo informado sobre el estado
              de su pedido.
            </p>
            <h2 className="mt-5 mb-5">
              <IoPricetag /> ¿Cuánto Cuesta el Envío?
            </h2>
            <p className="flow-text">
              La entrega es gratuita en la Península de España. Para envíos a
              las Islas Baleares y Canarias, se aplicará un pequeño suplemento.
            </p>
            <h2 className="mt-5 mb-5">
              <IoTime /> ¿Cuánto Tiempo Tardará?
            </h2>
            <p className="flow-text">
              Los plazos de entrega varían según el producto. Siempre que sea
              posible, mostramos plazos de entrega aproximados con cada
              producto, pero no podemos garantizar fechas de entrega exactas.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Delivery;
